import { useState, useEffect } from "react"

const useQuoteRotator = (quotes) => {
  const [ activeQuoteIndex, setActiveQuoteIndex ] = useState(-1)

  useEffect(() => {
    if(activeQuoteIndex === -1) {
      setActiveQuoteIndex(0);
      return
    }

    const wordCount = quotes[activeQuoteIndex].text.split(' ').length
    const duration = Math.max(wordCount * 350, 6500)

    const timeout = setTimeout(() => {
      setActiveQuoteIndex((activeQuoteIndex + 1) % quotes.length)
    }, duration)

    return () => {
      clearTimeout(timeout)
    }
  },[activeQuoteIndex])

  return [
    activeQuoteIndex,
    setActiveQuoteIndex
  ]
}

export default useQuoteRotator