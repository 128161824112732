import React from 'react'
import { Wrapper } from "./styles"
import { Dot } from "./Dot"

const Dots = ({ 
  quotes, 
  activeIndex,
  onDotClick
}) => {
  return (
    <Wrapper>
      {quotes.map((_quote, index) => (
        <Dot 
          active={index === activeIndex}
          onClick={() => onDotClick(index)}
        />
      ))}
    </Wrapper>
  )
}

export default Dots