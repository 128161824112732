
type quote = {
  text: string
  source: string
}

type Quotes = quote[]

export const quotes: Quotes = [
  {
    text: "..he's probably the greatest web developer in the world... no, wait... the UNIVERSE.",
    source: "anonymous client"
  },
  {
    text: "Mikki Freeman designed the website for my martial arts school. He made it so lively and interactive that a ninja actually jumped out of the computer and attacked one of the users. Long story short - we got sued.",
    source: "martial arts instructor"
  },
  {
    text: "...the best person you will ever meet.",
    source: "anonymous client"
  },
  {
    text: "You can brush and floss all you want, but your smile will never shine as brightly as Mikki's.",
    source: "Mikki's dentist"
  },
  {
    text: "Mikki did not allow his humanity to interfere with his work on a computer program that seeks to eliminate all human life. His devotion to the job is incalculable.",
    source: "Skynet mainframe"
  },
  {
    text: "I wanted to buy some JSX but apparently it's not a drug. It's a scripting language, and this guy has got mad skillz at it. You're not gonna tell my mom about the JSX thing, are you?",
    source: "misinformed juvenile delinquent"
  },
  {
    text: "Recently, they conducted a poll to reassess the seven wonders of the world... It is an absolute travesty of justice that Mikki Freeman did not make the cut.",
    source: "anonymous client"
  },
  {
    text: "We invented the Like button because our users needed a way to express how they feel about Mikki.",
    source: "Mark Zuckerberg"
  },
  {
    text: "Mikki is a brilliant web designer. Definitely one of the best. If you live in a country that is located in the future, he will complete the project much faster.",
    source: "anonymous client"
  },
  {
    text: "He's tolerable to be around, depending on the weather.",
    source: "overly negative Mikki critic"
  },
  {
    text: "The American dollar has weakened during the past 8 years, but some merits of value never change. Like the value of gold. Or Mikki Freeman.",
    source: "Wall Street analyst"
  },
  {
    text: "Mikki Freeman is the man. Not a man. The man. Meaning that he is somehow a quintessential man. Don't ask me -- I didn't invent the lingo.",
    source: "middle-aged Caucasian male"
  },
  {
    text: "I asked him for a quarter and he stopped to count out exactly twenty-five cents. The man believes in generosity as well as accuracy!",
    source: "impressed beggar"
  },
  {
    text: "Mikki Freeman is a brilliant flash of light in a dark and vacuous universe. However, his light and everything else is about to be consumed and obliterated by an enigmatic black hole, rendering his illumination entirely pointless.",
    source: "depressed astronomer"
  },
  {
    text: "Many IT contracts include legal phrases like 'an act of God'. Recently, there was a serious debate as to whether to legitimize the phrase 'an act of Mikki'.",
    source: "lawyer"
  },
  {
    text: "Mikki is short. He's compact. You can put him in your briefcase and take him to a meeting.",
    source: "Mikki's doctor"
  },
]
