import { Wrapper } from "./styles"

const Dot = ( { 
  active, 
  onClick 
}) => (
  <Wrapper 
    data-active={active} 
    onClick={onClick} 
  />
)

export default Dot