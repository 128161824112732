import React from "react"
import { Wrapper } from "./styles"
import Quote from "./Quote"
import useQuoteRotator from '../../hooks/useQuoteRotator';
import Dots from "./Dots";

export const Quotes = ({ quotes }) => {
  const [activeIndex, setActiveIndex ] = useQuoteRotator(quotes)

  return (
    <Wrapper>
      {quotes.map((quote, index) => (
        <Quote 
          text={quote.text}
          source={quote.source}
          visible={index === activeIndex}
        />
      ))}
      <Dots 
        activeIndex={activeIndex} 
        quotes={quotes}
        onDotClick={(setActiveIndex)}
      />
    </Wrapper>
  )
}

export default Quotes