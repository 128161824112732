import React from 'react';
import {
  Wrapper,
  Text,
  Source,
  InnerWrapper
} from './styles'

const Quote = ({
  visible,
  text,
  source
}) => (
  <Wrapper data-style-visible={visible}>
    <InnerWrapper>
      <Text>&#8220;{text}&#8221;</Text>
      <Source>— {source}</Source>
    </InnerWrapper>
  </Wrapper>
)

export default Quote
