import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 12px;
  cursor: pointer;

  &::after {
    background: #333;
    content: '';
    display: block;
    height: 2px;
    border-radius: 4px;
    width: 12px;
    font-size: 0;
    overflow: hidden;
    position: relative;
    transition: background-color 500ms;
    margin: 4px;
  }

  @media (hover: hover) {
    &:hover:after {
      background: #ccc;
    }
  }

  &[data-active="true"]:after {
    background: white;
    transition-delay: 500ms;
  }
`
