
import styled from 'styled-components'

export const Wrapper = styled.div`
  color: #fff;
  z-index: 10;
  width: auto;
  max-width: 890px;
  opacity: 0;
  transition-delay: 0ms;
  transition: opacity 2000ms;

  &[data-style-visible="true"] {
    opacity: 1;
    transition-delay: 2000ms;
  }
`

export const InnerWrapper = styled.div`
  transition: all 0ms;
  transition-delay: 2000ms;
  max-height: 0;
  max-width: 0;
  overflow: hidden;

  ${Wrapper}[data-style-visible="true"] & {
    max-width: 2000px;
    max-height: 2000px;
  }
`


export const Text = styled.div`
  margin-bottom: 8px;
  font-size:20px;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Source = styled.div`
  font-size:14px;
  text-transform:uppercase;
  color: #ff9900;
`
