import React from 'react';
import { quotes } from "./quotes"
import { Wrapper } from './styles'
import Quotes from "./components/Quotes"

function App() {
  return (
    <Wrapper>
      <Quotes quotes={quotes} />
    </Wrapper>
  ); }

export default App;
