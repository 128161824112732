import styled from 'styled-components'

export const Wrapper = styled.div`
  background: black;
  color: white;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  // padding: 0 64px;
  flex-direction: column
`


