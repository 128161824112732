import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
`
